// Header

$(document).ready(function(){
    if($("#header").hasClass("owl-carousel")){
        var owl_header = $("#header").owlCarousel({
            items: 1,
            loop:true,
            nav: false,
            dots: true,
            autoplay:true,
            autoplayTimeout:5000,
            autoplayHoverPause:true,
        });
    }  else {
        $("#header").parallax({
            imageSrc: $("#header").attr("data-image"),
            positionY: "bottom"
        });
    }
});