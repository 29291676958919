//=require vendors/jquery.js
//=require vendors/owl.carousel.min.js
//=require vendors/parallax.js
//=require vendors/perfect-scrollbar.js
//=require vendors/jquery.paroller.js
//=require vendors/svg-loader.js

//=require modules/nav.js
//=require modules/header.js
//=require modules/about.js
//=require modules/temoignages.js
//=require modules/partenaires.js
//=require modules/maps.js
//=require modules/services.js
//=require modules/examens.js

//=require blocks/block-slider.js

$(document).ready(function(){
    var multi_image = function(){
        $(".multi-image .img").css({
            width: ($(window).innerWidth()/2 - 15)+"px"
        });

        /*$(".block-single-img-text .img .block-img").css({
            width: ($(window).innerWidth()/2 - 15)+"px"
        });*/

        $(".multi-image-slider .items").css({
            width: ($(window).innerWidth()/2 - 15)+"px"
        });

        $(".multi-image .block").each(function(index,element){
            if($(element).find(".img").innerHeight()!= 0){
                $(element).css("height", $(element).find(".img").innerHeight()+"px");
            }
            $(element).find(".img2").css("width", $(element).innerWidth())
        });
    }
    multi_image();
    multi_image();
    $(window).on("resize", function(){
        multi_image();
    });
    
    if($(".multi-image-slider").length>0){
        $(".multi-image-slider").each(function(index,element){
            if($(element).find(".items .item").length > 1){
                $(element).find(".items").owlCarousel({
                    items: 1,
                    nav: true,
                    dots: false,
                });
            } else {
                $(element).find(".items").removeClass("owl-carousel");
            }
        });
    }

    /*if($(window).innerWidth() >= 992){
        $(".block-slider .info").paroller({
            factor: 0.1,            // multiplier for scrolling speed and offset
            type: 'foreground',     // background, foreground
            direction: 'vertical' // vertical, horizontal
        });
    }*/
});