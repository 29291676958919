$(document).ready(function(){
    if($(".examens").length > 0){
        var height =  ($("#nav").innerHeight()> 77)? 85: 77;
        $(document).on("click", ".examens .category > a", function(e){
            var $id= $(this).attr("href");
            $("html, body").animate({
                scrollTop: $($id).offset().top - height
            }, 500);
        });

        $(document).on("scroll", function(){
            if($(this).scrollTop()>= $("header").innerHeight()+85 ){
                $(".examens .btn_backTop").addClass("show");
            } else {
                $(".examens .btn_backTop").removeClass("show");
            }
        });

        $(document).on("click", ".examens .btn_backTop", function(){
            $("html, body").animate({
                scrollTop: $(".examens").offset().top - height
            }, 500);
            $(".examens .btn_backTop").removeClass("show");
        });

        if(window.location.hash){
            $("html, body").animate({
                scrollTop: $(window.location.hash).offset().top - height
            }, 500);
        }
    }
});