// Nav

$(document).ready(function(){
    $(document).on("scroll", function(){
        if($(this).scrollTop() > $("#nav").outerHeight()){
            $("#nav").addClass("fixed");
        } else {
            $("#nav").removeClass("fixed");
        }
    });

    if($(window).outerWidth()<= 1250){
        $(document).on("click", "#nav .btn-close", function(){
            $("#nav .sous-menu .item .item-content").removeClass("open");
            $("#nav").toggleClass("menu-open");
            $("body,html").toggleClass("hidden");
        });
    } else {
        $("#nav").removeClass("menu-open");
        $("#nav .sous-menu .item .item-content").removeClass("open");
    }

    var element_active = 0 ;
    $(document).on("mouseenter", "#nav .menu-content .menu > ul > li", function(e){
        if($(this).hasClass("active")){
            element_active = $(this).index();
        } else {
            element_active = $("#nav .menu-content .menu > ul > li.active").index();
            $(this).addClass("active").siblings().removeClass("active");
        }
    });
    
    $(document).on("mouseleave", "#nav .menu-content .menu > ul > li", function(e){
        $("#nav .menu-content .menu > ul > li").eq(element_active).addClass("active").siblings().removeClass("active");
    });

    $(document).on("click", ".btn-back", function(){
        $(this).parent().removeClass("open");
        $("#nav .inner-content .menu-content .menu").removeClass("nav3-open");
    });

    $(document).on("click", "#nav .sous-menu .item > h5", function(){
        $("#nav .sous-menu .item .item-content").removeClass("open");
        $("#nav .inner-content .menu-content .menu").removeClass("nav3-open");
        $(this).parent().find(".item-content").addClass("open");
        $("#nav .inner-content .menu-content .menu").addClass("nav3-open");
    });

    if($(window).outerWidth() > 1250){
        new PerfectScrollbar(document.querySelector("#nav .sous-menu"));
    }
});