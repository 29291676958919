$(document).ready(function(){
    var owl_blockSlider = $(".block-slider .items").owlCarousel({
        items: 1,
        nav: false,
        dots: false,
        loop: true,
        autoplay:true,
        autoplayTimeout:5000,
        autoplayHoverPause:true
    });
});