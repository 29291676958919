// Partenaires

$(document).ready(function(){

    if($(window).innerWidth() > 767){
        var owl_partenaires = $(".partenaires .inner-content").owlCarousel({
            items: 1,
            nav: false,
            dots: false,
            responsiveClass:true,
            /*responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 2
                },
                900: {
                    items: 3
                },
                1200: {
                    items: 4
                }
            }*/
        });
    } else {
        $(".partenaires .inner-content").removeClass("owl-carousel");
    }
});