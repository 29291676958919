// About

$(document).ready(function(){
    if($(".about .slider")){
		if(!$(".about").hasClass("mobile")){
			$(".about .slider .block-img .inner-text").each(function(index,element){
				console.log($(element).find("p").innerHeight());
				$(element).css("height", $(element).find("p").outerHeight() + 100);
			});
		}
			
		$(window).on("load", function(){
            if(!$(".about").hasClass("mobile")){
                $(".about .slider .block-img .inner-text").each(function(index,element){
                    console.log($(element).find("p").innerHeight());
                    $(element).css("height", $(element).find("p").outerHeight() + 100);
                });
            }
        });
		
        var owl_about = $(".about .slider").owlCarousel({
            items: 1,
            nav: false,
            dots: true,
			autoHeight:true
        });

        
    }
});