// Services

$(document).ready(function(){
	function getHeight(){
		var height_services = 0; 
		$(".services .detail").each(function(index,element){
			if(height_services < $(element).innerHeight()){
				height_services= $(element).innerHeight()
			}
		});
		$(".services .detail").css("height", height_services);
	}
	
	if($(".services .detail").length > 0){
		getHeight();
		$(window).on("resize", function(){
			getHeight();
		});
	}
});